@font-face {
  font-family: SmallFont;
  src: url("./assets/fonts/SmallFont.ttf");
}

@font-face {
  font-family: image-font;
  src: url("./assets/fonts/AlienFont.ttf");
}

@font-face {
  font-family: small-font;
  src: url("./assets/fonts/SmallestFont.ttf");
}

@font-face {
  font-family: LargeFont;
  src: url("./assets/fonts/LargeFont.ttf");
}

/* * {
  overflow: hidden;
} */

/* .toast-container {
  font-family: "SmallFont";
} */

.trivia {
  background-color: #ddd;
}

.App {
  max-width: 450px;
  background-image: linear-gradient(#ace7e0, #eee490);
  margin: 0 auto;
  /* height: 100vh; */
  overflow-y: auto;
  z-index: 0;
  position: relative;
}

.homecontainer {
  position: relative;
}

.menu_content {
  position: absolute;
  width: 300px;
  background-color: white;
  flex-direction: column;
  padding: 30px;
  z-index: 1;
  display: none;
}

.menu_content_open {
  position: absolute;
  width: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  z-index: 1;
  animation: menuopen 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

@keyframes menuopen {
  from {
    left: -300px;
  }
  to {
    left: 0px;
  }
}

.menu_content_close {
  position: absolute;
  width: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  z-index: 1;
  animation: menuclose 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

@keyframes menuclose {
  from {
    left: 0px;
  }
  to {
    left: -300px;
  }
}

.menu-close {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  padding-right: 20px;
}

.menu-users {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color: #000; */
}

.menu-items {
  cursor: pointer;
  margin-top : 26px;
}

.menu-icon {
  font-family: "image-font";
  font-size: 28px;
  padding-right: 20px;
  color: "#8dc63f";
  display : inline-block;
  height : 20px;
  line-height : 20px;
}

.menu-item {
  font-family : 'SmallFont';
  font-size : 22px;
  color: black;
  font-weight:lighter;
  display : inline-block;
  height : 20px;
  line-height : 20px;
}

.is-auth {
  position: absolute;
  bottom: 0;
  padding-bottom: 50px;
  cursor: pointer;
}

.user-avator {
  font-family: "image-font";
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-size: 100px;
  padding-left: 13px;
}

.user-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 40px !important;
  padding: 10px;
  color: #000;
}

hr {
  border: 1px solid rgb(20, 15, 15) !important;
  width: 100%;
}

/*---------- Begin header --------*/

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  font-family: "LargeFont";
}

.menu_btn {
  display: flex;
  cursor: pointer;
  padding: 30px 0 0 40px;
  justify-content: center;
  align-items: center;
}

/* .menu_btn:hover {
  color: #49186b;
} */

.collection_btn {
  display: flex;
  cursor: pointer;
  padding: 30px 40px 0 0;
  justify-content: center;
  align-items: center;
}

.collection_btn span {
  padding-right: 10px;
}

.collection_btn button {
  color:  #ffffff;
  background: #00C876;
  border: none;
  padding: 5px 10px;
}

/* .collection_btn:hover {
  color: #49186b;
} */

/*---------- End header --------*/

/*------------- Begin Game Rule --------------*/

.game-rule {
  position: relative;
  background-color: #FEDE7C;
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
}

.game-rule-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game_rule_image {
  margin-top: 30px;
  width: 110px;
}

.game-rule-title {
  color: #7F3B8A;
  font-family: "LargeFont";
  font-size: 25px;
  margin: 30px 0;
}

.game-rule-content {
  width: 80%;
}

.game-rule-content p {
  font-family: "SmallFont";
  color: #7F3B8A;
  font-size: 15px;
}

/*------------- End Game Rule --------------*/

/*---------- Begin Home_Image --------*/

.home_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  padding-top: 3vh;
}

.home_image {
  width: 90%;
  padding-bottom: 10px;
}

.home_nft {
  margin-top: 4vh;
  /* width: 50%; */
  width: 160px;
}

.home_title {
  text-align: center;
  padding: 16.5px 0 12.75px 0;
  color: #B94A92;
  font-weight: bold;
  font-size: 13px;
  font-family: "LargeFont";
}

.home-header {
  text-align: center;
  color: #B94A92;
  font-weight: bold;
  font-size: 20.5px;
  font-family: "LargeFont";
}

.home-prize-header {
  font-family: "LargeFont";
  color: #B94A92;
  font-size: 11px;
}

.home-prize-content {
  width: 90%;
  height: 55px;
  background-image: url("./assets/icons/home-prize.png");
  background-size: 100% 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: -5px !important;
  background-repeat: no-repeat;
}

.home-prize-content span {
  margin-top: 10px;
  color: white;
  font-family: "LargeFont";
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}

.home-prize-subheader {
  color: #B94A92;
  font-family: "SmallFont";
  font-size: 15px;
}

.home-prize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;
  width: 100%;
}

/*---------- End Home_Image --------*/

/*---------- Begin TimeCounter --------*/

.btn-group-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.btn-group-header button {
  border: none;
  background: #FFAEA4;
  color: #ffffff;
  width: 100%;
  padding: 15px 0;
  font-family: "LargeFont";
  font-size: 11px;
}

.time-counter-pending {
  display: flex;
  height: 80%;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.timecounter {
  /* padding-top: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.prize {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prize_amount {
  padding: 10px;
  color: white;
  font-size: 40px;
  font-family: "LargeFont";
  text-align: center;
}

.prize_title {
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 11px;
  font-family: "LargeFont";
  text-align: center;
}

.btn_group {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
}

.livegame_btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #FF9587;
}

/* .livegame_btn:hover {
  background-color: #2d193b;
} */

.countdown_hr {
  color: white;
  font-family: "LargeFont";
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.regester {
  color: #ffffff;
  font-family: "SmallFont";
  font-size: 13px;
  text-align: center;
}

.countdown_ready {
  font-family: "SmallFont";
  color: #ffff;
  font-size: 13px;
  text-align: center;
}

.practice-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.practice-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SmallFont";
  text-decoration: underline;
  font: 14px;
  color: #B94A92;
}

.practice-btn:hover {
  color: #B94A92;
}

.practice-header {
  font: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  font-family: "SmallFont";
}

.sound-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.turn-sound {
  text-align: center;
  background-color: #47CBBA;
  padding: 15px 0;
  color: white;
  font-family: "LargeFont";
  cursor: pointer;
  width: 85%;
  font-size: 13px;
}

/*---------- End TimeCounter --------*/

/*---------- Begin Social --------*/
.social-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 40px !important;
}

.social-btns {
  display: flex;
  flex-direction: column;
}

.social-btn {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  background-color: #301947;
  color: white;
  font-family: "SmallFont";
  font-size: 15px;
}

.social-btn:hover {
  background-color: #2d193b;
}

.sign_with_email {
  padding-top: 40px;
  font-family: "SmallFont";
  color: #B94A92;
  font-size: 15px;
}

.sign_with_email span {
  padding-top: 20px;
  font-family: "SmallFont";
  color: #B94A92;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.policy {
  display: flex;
  flex-direction: row;
  padding: 20px;
  font-family: "SmallFont";
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #B94A92;
  font-size: 11px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.policy a {
  color: white;
  text-decoration: none !important;
}

.policy span {
  font-size: 13px;
}

.social-icons {
  margin-left: 20px;
  width: 30px;
  height: 30px;
  margin-right: 50px;
}

/*---------- End Social --------*/

/*---------- Begin Email --------*/

/* .toast-container {
  font-family: "SmallFont";
  width: 100%;
} */

.sign-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}

.email_login {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  z-index: 1;
}

.email-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email_tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006f9d;
  font-family: "SmallFont";
  text-decoration: underline;
}

/* .email_tab:hover {
  color: #49186b;
} */

.email_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .email_form input {
  background-color: #190627;
  border: 1px solid #372544;
  color: white;
  font-family: "SmallFont";
  font-size: 15px;
  width: 100%;
  padding-left: 10px !important;
} */

.input-field {
  border: none;
  font-family: "SmallFont";
  font-size: 17px !important;
  width: 100%;
  padding: 9px 0 7px 20px;
  margin-bottom: 5px;
}

::placeholder {
  color: #006f9d;
}

.email-btn {
  margin-top: 10px;
  padding: 10px 0;
  background-color: #006f9d;
  color: white;
  font-family: "SmallFont";
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  width: 100%;
}

/* .email-btn:hover {
  background-color: #2d193b;
} */

.social_login {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_login span {
  cursor: pointer;
  color: #B94A92;
  font-family: "SmallFont";
  text-decoration: underline;
}

.social_login span:hover {
  color: #49186b;
}

.input-icons {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.input-icons svg {
  /* position: absolute; */
  color: white;
}

.input-icon {
  width: 40px;
}

.forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006f9d;
  text-decoration: underline;
  font-family: "SmallFont";
  cursor: pointer;
}

/* .forgot-password:hover {
  color: #49186b;
} */

/*---------- End Email --------*/

/*---------- Begin Practice --------*/

.practice {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  position: relative;
  padding-top: 30px;
  align-items: space-evenly;
}

.question-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.question-image {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 46%;
  margin: 2% auto;
  position: relative;
  height: calc(var(width));
  min-height : calc(var(width));
  background-color: #82D8C7;
}

.image-border {
  border: 5px solid #ffffff;
}

.image-select {
  background: white no-repeat;
  align-items: center;
  height: 22px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index : 10;
}

.answer-percent {
  font-family: "small-font";
  font-size: 15px;
  position: absolute;
  right: 0;
  padding: 0 0px;
  padding-top: 2%;
}

.select-image {
  width: 100%;
  max-height: fit-content;
}

.ready-image {
  width: 100%;
  background-repeat: no-repeat, repeat;
  background-color: #11bec9 !important;
  opacity : 30%;
  z-index: -1;
  height: fit-content;
}

.color-candidate {
  background-color: #B94A92;
  width: 13px;
  height: 13px;
}

.correct-answer {
  background-color: #99cc00;
  width: 13px;
  height: 13px;
}

.question-number {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  font-family: "LargeFont";
  color: #5752bf;
  font-size: 17px;
}

.question-problem {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  font-family: "SmallFont";
  color: #8b58bf;
  font-size: 20px;
  min-height : 70px;
}

.question-countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "LargeFont";
  color: #5752bf;
  font-size: 50px;
  /* height: 150px; */
}

.question-footer {
  display: flex;
  gap: 2vw;
}

.question-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #5752bf;
  font-family: "LargeFont";
  width: 100%;
  bottom: 0;
  margin: 20px 0;
}

.score {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.score-number {
  font-size: 20px;
  font-family: "LargeFont";
}

.score-content {
  font-size: 12px;
  font-family: "SmallFont";
  color: white;
}

.average-time {
  display: flex;
  flex-direction: Row;
  justify-content: center;
  align-items: flex-start;
}

.average-sec {
  font-size: 20px;
  font-family: "LargeFont";
  padding-right: 5px;
}

.average-msec {
  font-size: 12;
  font-family: "LargeFont";
}

.lifeline {
  width: 30px;
  cursor: pointer;
}

.score-level {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}

.result-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: relative;
}

.result-content-answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: relative;
}

.time-up {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "LargeFont";
  font-size: 15px;
  color: #5752bf ;
  padding-bottom: 10px;
}

.result-evaluation {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.result-contents {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.result-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.answer-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-time {
  font-size: 15px;
  color: #B94A92;
  font-family: "SmallFont";
  padding-top: 20px;
}

.answer-result {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.result-countdown {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  position: absolute;
  font-family: "LargeFont";
  color: #B94A92;
  justify-content: center;
  align-items: flex-end;
  right: 0;
}

/*---------- End Practice --------*/

/*---------- Begin Result --------*/

.result {
  display: flex;
  flex-direction: column;
  position: relative;
}

.result-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result-avator {
  margin-top: 60px;
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
}

.result-title {
  font-size: 20px;
  font-family: "LargeFont";
  color: #019db4;
}

.result-title-win {
  font-size: 20px;
  font-family: "LargeFont";
  color: white;
}

.result-context-win {
  padding-top: 20px;
  text-align: center;
  font-family: "SmallFont";
  color: #019db4;
  margin: 0 40px;
}

.result-context {
  padding-top: 15px;
  font-family: "SmallFont";
  color: #019db4;
}

.result-ranks {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-rank {
  font-size: 40px;
  font-family: "LargeFont";
  color: #006f9d;
}

.rank-text {
  padding-top: 10px;
  font-family: "LargeFont";
  color: #019db4;
  font-size: 13px;
}

.review-answers-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6ec8ba;
  padding: 15px;
  margin: 0 40px;
  width: 80%;
  cursor: pointer;
}

/* .review-answers-btn:hover {
  background-color: #2d193b;
} */

.review-answer {
  font-size: 15px;
  font-family: "LargeFont";
  color: white;
}

.review-span {
  font-family: "SmallFont";
  color: #006f9d;
}

.result-btns {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  bottom: 0;
  z-index: 0;
}

.result-btn {
  background-color: #6ec8ba;
  margin: 10px 40px 0 40px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-family: "SmallFont";
  font-size: 15px;
}

.result-btn:hover {
  background-color: #2d193b;
}

.result-btn span {
  font-size: 15px;
  font-family: "SmallFont";
  color: white;
  display: flex;
  text-align: center;
}

.result-btn svg {
  left: 0;
  margin-left: 20px;
  width: 30px;
  height: 30px;
  margin-right: 80px;
  color: #5165f6;
}

.question-move-next {
  font-family: "LargeFont";
  color: #B94A92;
  font-size: 16px;
  cursor: pointer;
  text-align: right;
  display : flex;
  align-items : center;
}

.question-move-previous {
  font-family: "LargeFont";
  color: #B94A92;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  display : flex;
  align-items : center;
}

.image-description {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B94A92;
  font-family: "SmallFont";
  text-align: center;
}

.result-group-btns {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  width: 80%;
}

.discord-btn {
  margin-right: 10px;
  width: 100%;
  background-color: #6ec8ba;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.leaderboard-btn {
  background-color: #6ec8ba;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.result-btn-image {
  width: 30px;
}

/*---------- End Result --------*/

.loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.result-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.result-leaderboard {
  display: flex;
  flex-direction: column;
  max-width: 30px;
  align-items: flex-end;
}

.result-leaderboard span {
  font-family: "SmallFont";
  color: #B94A92;
  font-size: 13px;
  cursor: pointer;
}

.highscore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "LargeFont";
  color: #B94A92;
  width: 100%;
  padding-top: 50px;
}

.high-score-title {
  color: white;
}

.leaderboard {
  font-size: 12px;
  margin: 30px 0;
}

th,
td {
  padding: 10px;
}

table th {
  text-align: left;
}

.leaderboard .player {
  width: 100%;
}

.td-avator {
  font-family: "image-font" !important;
  font-size: 20px;
}

.table-no {
  text-align: right;
}

.table-score {
  text-align: right;
}

.time {
  font-size: 8px;
}

.leaderboard .content {
  color: white;
}

.terms {
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (max-height: 700px) {
  /* .home_image {
    width: 100%;
  } */

  .btn_group {
    padding: 10px;
  }
  .home_nft {
    margin-top: 4vh;
    width: 50%;
  }
}

/* Main Screen */

@media only screen and (max-height: 550px) {
  .prize_amount {
    font-size: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .prize_title {
    padding-top: 0;
    padding-bottom: 0;
  }
  .btn_group {
    padding-top: 10px;
  }
}

@media only screen and (max-height: 460px) {
  .prize_amount {
    font-size: 10px;
    padding: 0 10px;
  }
  .prize_title {
    padding: 0 10px;
    font-size: 10px;
  }
  .btn_group {
    padding-top: 10px;
  }

  .home_title {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-height: 680px) {
  .policy {
    display: none !important;
  }
}


