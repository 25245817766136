.helicopter-animation {
    right : -92%; 
    animation : helicopter-animation 20s;
    animation-delay: 0.3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes helicopter-animation {
    0% { right : -92%; }
    50% {right : 101%; }
    100% {right : 101%; }
}
