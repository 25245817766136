.helicopter-animation {
    right : -130%; 
    animation : helicopter-animation 40s;
    /* animation-delay: 0.3s; */
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
.balloon-animation {
    width : 20%;
    right : -25%;
    animation : balloon-animation 40s;
    animation-delay: 12s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes helicopter-animation {
    0% { right : -130%; }
    25% {right : 101%; }
    100% {right : 101%; }
}

@keyframes balloon-animation {
    0% { right : -25%; }
    75% {right : 101%; }
    100% {right : 101%; }
}